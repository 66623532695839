import * as Sentry from '@sentry/remix';
import { HydratedRouter } from 'react-router/dom';
import { useLocation, useMatches } from 'react-router';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import posthog from 'posthog-js';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://fe6fd0f17c07d9f45c02c8b7463df53f@o4507217598611456.ingest.us.sentry.io/4507217602543616',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: process.env.NODE_ENV,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

// Since Remix apps don’t include Node’s globals in the browser by default, we need to define process on the client.
if (typeof process === 'undefined') {
  (window as any).process = { env: {} };
}

function PosthogInit() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      posthog.init('phc_AOyOBE3maVGa1GgIg6y9D2pJl1EHY1wkSpioM9cZZVv', {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
        session_recording: {
          maskAllInputs: false,
        },
      });
    }
  }, []);

  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
      <PosthogInit />
    </StrictMode>
  );
});
